$soft-white: #eeeeee;
$faded-white: #dededeff;
$transparent-gray: rgba(128, 128, 128, 0.2);
$denim: #444465;
$charcoal: #252525;
$shadow: #1a1a1a;
$midnight: #121212;
$pink: #eccfe9;
$pink-cream: #f0d6ee;
$extreme-white: #ffffff;
$extreme-black: #000000;
$invisible: rgba(0, 0, 0, 0);
$font-family-body: Ubuntu;
