@import "../palette";

// Background colors
$primary-background-color: $soft-white;
$primary-paper-color: $faded-white;
// Font colors
$primary-font-color: $charcoal;
$secondary-font-color: $midnight;
// Sidebar
$sidebar-background: linear-gradient(146deg, rgb(6 50 162) 0%, rgb(98 239 239 / 47%) 100%);
$sidebar-font-color: $extreme-white;
$sidebar-option-background: $invisible;
$sidebar-option-shadow: -3px 2px white;
// Footer
$footer-background: $invisible;
// Skeletons
$skeleton-color: $transparent-gray
